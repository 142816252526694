<template>
  <div class="flex header-search" :style="{width: onMobile?'100%':'65%'}">
    <div class="search-full-container w-full" style="height: 40px">
      <vx-auto-suggest
        ref="navbarSearch"
        :data="data"
        search_key="name"
        background-overlay
        class="w-full"
        inputClassses="w-full vs-input-no-border vs-input-no-shdow-focus"
        icon="SearchIcon"
        placeholder="Search anything..."
        @input="hnd_search_query_update"
        @selected="selected"
        @closeSearchbar="showFullSearch = false">
        <template v-slot:search="{ query }">
          <p class="font-semibold text-primary">{{ query }}</p>
        </template>
        <template v-slot:group="{ group_name }">
          <p class="font-semibold text-primary">{{ group_name }}</p>
        </template>

        <!-- Pages Suggestion -->
        <template v-slot:Products="{ suggestion }">
          <div class="flex items-center leading-none py-1">

            <img :src="suggestion.files[0].path" class="h-8 w-8" v-if="suggestion.files.length>0" alt="">
            <span class="ml-1">{{ suggestion.name }}</span>
          </div>
        </template>

        <!-- No Items Slot -->
        <template v-slot:noResult="{ group_name }">
          <div class="flex items-center">
            <feather-icon icon="InfoIcon" svgClasses="h-5 w-5" class="mr-4" />
            <span>No results found.</span>
          </div>
        </template>

      </vx-auto-suggest>
    </div>
  </div>
</template>

<script>
import VxAutoSuggest from '@/components/vx-auto-suggest/VxAutoSuggest.vue';

export default {
  components: {
    VxAutoSuggest
  },
  props:{
    onMobile:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      data: {
        Products:{
          key:'name',
          data:this.$store.state.products
        }
      },
      showFullSearch: false,
    }
  },
  methods: {
    selected(item) {
      console.log(item);
      // if (item.Products.Products.length>0){
        this.$router.push('/products/'+item.Products.slug);
      // }
    },
    hnd_search_query_update(query) {
      this.m_pixel_Search({search_string:query})
      this.$store.commit('TOGGLE_CONTENT_OVERLAY', query ? true : false)
    }
  }
}

</script>
