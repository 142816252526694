<!-- =========================================================================================
	File Name: VerticalNavMenuGroup.vue
	Description: Vertical NavMenu Group Component. Extends vuesax framework's 'vs-sidebar-group' component
	Component Name: VerticalNavMenuGroup
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div
    class  = "vs-sidebar-group pt-1"
    :class = "[
      {'vs-sidebar-group-open'            : openItems        },
      {'vs-sidebar-group-active'          : open             }
    ]"
    @mouseover = "mouseover"
    @mouseout  = "mouseout">

    <!-- Group Label -->
    <div @click="clickGroup" class="group-header w-full">
        <span class="flex items-center w-full">

          <!-- Group Icon -->
          <feather-icon
            :icon       = "'TagIcon'" />

          <!-- Group Name -->
          <span v-show="!verticalNavMenuItemsMin" class="truncate mr-3 select-none">Categories</span>
        </span>

      <!-- Group Collapse Icon -->
      <feather-icon
        v-show     = "!verticalNavMenuItemsMin"
        :class     = "[{'rotate90' : openItems}, 'feather-grp-header-arrow']"
        :icon       = "$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
        svg-classes= "w-4 h-4" />

      <!-- Group Tooltip -->
      <span class="vs-sidebar--tooltip">Categories</span>
    </div>
    <!-- /Group Label -->

    <!-- Group Items -->
    <ul ref="items" :style="styleItems" class="vs-sidebar-group-items">
      <div v-if="$store.state.windowWidth > 768">
        <v-nav-mega-menu-item :key="i" :category="c" v-for="(c,i) in categories"></v-nav-mega-menu-item>
      </div>
      <div v-else>
        <category-nav-menu-group
          v-for="(c,i) in categories"
          :key="`group-${i}`"
          :openHover="openGroupHover"
          :group="c"
          :groupIndex="i"
          :open="isCategoryGroupActive(c)"
        >
        </category-nav-menu-group>
      </div>
    </ul>
    <!-- /Group Items -->
  </div>
</template>


<script>
  import VNavMegaMenuItem from './VerticalNavMegaMenuItem.vue'
  import CategoryNavMenuGroup from './CategoryNavMenuGroup.vue'

  export default {
    name  : 'v-nav-menu-group',
    props : {
      categories      : { type: Array },
      open       : { type: Boolean, default: true },
      openGroupHover:   { type: Boolean, default: false },
    },
    components: {
      VNavMegaMenuItem,
      CategoryNavMenuGroup,
    },
    data: () => ({
      maxHeight : '0px',
      openItems : false
    }),
    computed: {
      isCategoryGroupActive() {
        return (item) => {
          const path        = this.$route.fullPath
          let open          = false
          const routeParent = this.$route.meta ? this.$route.meta.parent : undefined

          let func = (item) => {
            if (item.sub_categories) {
              item.sub_categories.forEach((item) => {
                if ((path == 'category/'+item.slug || routeParent == item.slug)) { open = true}
                else if (item.sub_categories) { func(item) }
              })
            }
          }

          func(item)
          return open
        }
      },
      verticalNavMenuItemsMin() { return this.$store.state.verticalNavMenuItemsMin },
      styleItems() {
        return { maxHeight: this.maxHeight }
      },
      itemIcon() {
        return (index) => {
          if (!((index.match(/\./g) || []).length > 1)) return "CircleIcon"
        }
      },
      isGroupActive() {
        return (item) => {
          const path        = this.$route.fullPath
          let open          = false
          const routeParent = this.$route.meta ? this.$route.meta.parent : undefined

          let func = (item) => {
            if (item.submenu) {
              item.submenu.forEach((item) => {
                if ((path == item.url || routeParent == item.slug) && item.url) { open = true}
                else if (item.submenu) { func(item) }
              })
            }
          }

          func(item)
          return open
        }
      },
    },
    watch: {
      // OPEN & CLOSES DROPDOWN ON ROUTE CHANGE
      '$route'() {
        if (this.verticalNavMenuItemsMin) return

        let scrollHeight = this.scrollHeight

        // Collapse Group
        if (this.openItems && !this.open) {

          this.maxHeight = `${scrollHeight}px`
          setTimeout(()  => {
            this.maxHeight = `${0}px`
          }, 50)

          // Expand Group
        } else if (this.open) {

          this.maxHeight = `${scrollHeight}px`
          setTimeout(()  => {
            this.maxHeight = 'none'
          }, 300)
        }
      },
      maxHeight() {
        this.openItems = this.maxHeight != '0px'
      },
      // OPEN AND CLOSES DROPDOWN MENU ON NavMenu COLLAPSE AND DEFAULT VIEW
      '$store.state.verticalNavMenuItemsMin'(val) {
        let scrollHeight = this.$refs.items.scrollHeight

        if (!val && this.open) {

          this.maxHeight = `${scrollHeight}px`
          setTimeout(()  => {
            this.maxHeight = 'none'
          }, 300)
        } else {

          this.maxHeight = `${scrollHeight}px`
          setTimeout(()  => {
            this.maxHeight = '0px'
          }, 50)
        }
        if (val && this.open) {

          this.maxHeight = `${scrollHeight}px`
          setTimeout(()  => {
            this.maxHeight = '0px'
          }, 250)
        }
      }
    },
    methods: {
      clickGroup() {
        if (!this.openHover) {

          let thisScrollHeight = this.$refs.items.scrollHeight

          if (this.maxHeight == '0px') {
            this.maxHeight = `${thisScrollHeight}px`
            setTimeout(() => {
              this.maxHeight = 'none'
            }, 300)

          } else {
            this.maxHeight = `${thisScrollHeight}px`
            setTimeout(() => {
              this.maxHeight = `${0}px`
            }, 50)
          }

          this.$parent.$children.map((child) => {
            if (child.isGroupActive) {
              if (child !== this && (!child.open) && child.maxHeight != '0px') {
                setTimeout(() => {
                  child.maxHeight = `${0}px`
                }, 50)
              }
            }
          })
        }
      },
      mouseover() {
        if (this.openHover) {
          let scrollHeight = this.$refs.items.scrollHeight
          this.maxHeight   = `${scrollHeight}px`
        }
      },
      mouseout() {
        if (this.openHover) {
          let scrollHeight = 0
          this.maxHeight   = `${scrollHeight}px`
        }
      }
    },
    mounted() {
      this.openItems = this.open
      if (this.open) { this.maxHeight = 'none' }
    },
  }

</script>


<style lang="scss">
  @import "@/assets/scss/vuexy/components/verticalNavMenuGroup.scss"
</style>
