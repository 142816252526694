<!-- =========================================================================================
    File Name: VerticalNavMenuItem.vue
    Description: Vertical NavMenu item component. Extends vuesax framework's 'vs-sidebar-item' component
    Component Name: VerticalNavMenuItem
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    @mouseover="hover = true"
    @mouseleave="hover = false"
    class="vs-sidebar--item"
    :class="[
      {'disabled-item pointer-events-none' : isDisabled}
    ]" >

      <router-link
        tabindex="-1"
        exact
        :class="[{'router-link-active': $route.query.catgegory == category.slug}]"
        :to="'/products?category='+category.slug"
        :target="target" >
        <span v-show="!verticalNavMenuItemsMin" class="truncate">{{ category.name }}</span>
      </router-link>
    <div v-if="category.sub_categories.length">
      <div v-if="hover && $store.state.windowWidth > 768" class="mega_menu">
        <div class="vx-row">
          <div v-for="(sc, i) in category.sub_categories" :key="sc.id" class="vx-col md:w-1/2">
            <router-link exact  :to="'/products?category='+sc.slug"><span class="text-lg font-bold">{{sc.name}}</span></router-link>
            <div v-if="sc.sub_categories">
              <router-link v-for="(ssc,i) in sc.sub_categories" exact :key="ssc.id" class="pt-0 pb-0 pl-10" :to="'/products?category='+ssc.slug">{{ssc.name}}</router-link>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'v-nav-menu-item',
  props: {
    category    : { type: Object,                 default: null             },
    target      : { type: String,                 default: '_self'          },
    isDisabled  : { type: Boolean,                default: false            },
    verticalNavMenuItemsMin:{type:Boolean, default:false}
  },
  data(){
    return {
      hover:false,
    }
  }
}

</script>
<style>
  .mega_menu {
    position: fixed;
    margin-top: -50px;
    left: 260px;
    z-index: 10000000000000000;
    background: white;
    width: 400px;
    height: 450px;
  }
</style>

