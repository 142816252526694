<template>
  <div v-if="$store.state.theme == 'light'" class="vs-sidebar--item">
    <a @click="setTheme('dark')" class="cursor-pointer">
    <feather-icon icon="MoonIcon" class="cursor-pointer ml-1 navbar-fuzzy-search"></feather-icon>
    <span class="truncate">Enable Dark Mode</span>
    </a>
  </div>
  <div v-else class="vs-sidebar--item">
    <a @click="setTheme('light')" class="cursor-pointer">
    <feather-icon icon="SunIcon" class="cursor-pointer ml-1 navbar-fuzzy-search"></feather-icon>
    <span class="truncate">Disable Dark Mode</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'night_mode',
  methods: {
    setTheme(theme){
      this.$store.commit('SET_THEME', theme);
      localStorage.setItem('theme', theme);
    },
  }
}

</script>

