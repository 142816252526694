/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/",
    name: "Home",
    slug: "products",
    icon: "HomeIcon",
  },
  {
    url: "/wish-list",
    name: "Wish list",
    slug: "wish-list",
    icon: "HeartIcon",
  },
  {
    url: "/products",
    name: "All products",
    slug: "products",
    icon: "GridIcon",
  },
  {
    url:null,
    category:true,
  },
  {
    url:null,
    night_mode:true,
  },
]
