var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex header-search",style:({width: _vm.onMobile?'100%':'65%'})},[_c('div',{staticClass:"search-full-container w-full",staticStyle:{"height":"40px"}},[_c('vx-auto-suggest',{ref:"navbarSearch",staticClass:"w-full",attrs:{"data":_vm.data,"search_key":"name","background-overlay":"","inputClassses":"w-full vs-input-no-border vs-input-no-shdow-focus","icon":"SearchIcon","placeholder":"Search anything..."},on:{"input":_vm.hnd_search_query_update,"selected":_vm.selected,"closeSearchbar":function($event){_vm.showFullSearch = false}},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var query = ref.query;
return [_c('p',{staticClass:"font-semibold text-primary"},[_vm._v(_vm._s(query))])]}},{key:"group",fn:function(ref){
var group_name = ref.group_name;
return [_c('p',{staticClass:"font-semibold text-primary"},[_vm._v(_vm._s(group_name))])]}},{key:"Products",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('div',{staticClass:"flex items-center leading-none py-1"},[(suggestion.files.length>0)?_c('img',{staticClass:"h-8 w-8",attrs:{"src":suggestion.files[0].path,"alt":""}}):_vm._e(),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(suggestion.name))])])]}},{key:"noResult",fn:function(ref){
var group_name = ref.group_name;
return [_c('div',{staticClass:"flex items-center"},[_c('feather-icon',{staticClass:"mr-4",attrs:{"icon":"InfoIcon","svgClasses":"h-5 w-5"}}),_c('span',[_vm._v("No results found.")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }