<!-- =========================================================================================
	File Name: VerticalNavMenuGroup.vue
	Description: Vertical NavMenu Group Component. Extends vuesax framework's 'vs-sidebar-group' component
	Component Name: VerticalNavMenuGroup
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div
    class  = "vs-sidebar-group"
    :class = "[
      {'vs-sidebar-group-open'            : openItems        },
      {'vs-sidebar-group-active'          : open             },
      {'disabled-item pointer-events-none': group.isDisabled }
    ]"
    @mouseover = "mouseover"
    @mouseout  = "mouseout">

      <!-- Group Label -->
      <div class="group-header w-full">
        <span   @click="clickGroup"  class="flex items-center w-full">

          <!-- Group Icon -->
          <feather-icon
            v-if        = "group.icon  || (this.groupIndex > Math.floor(this.groupIndex))"
            :icon       = "group.icon  || 'CircleIcon'"
            :svgClasses = "{ 'w-3 h-3' : this.groupIndex % 1 != 0 }" />

          <!-- Group Name -->
         <span @click.stop="$router.push('/products?category='+group.slug)" v-show="!verticalNavMenuItemsMin" class="truncate mr-3 select-none">{{  group.name }}</span>
        </span>

        <!-- Group Collapse Icon -->
        <!--<feather-icon @click="clickGroup"-->
          <!--v-show     = "!verticalNavMenuItemsMin"-->
          <!--:class     = "[{'rotate90' : openItems}, 'feather-grp-header-arrow']"-->
          <!--:icon       = "$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"-->
          <!--svg-classes= "w-4 h-4" />-->
        <vs-button radius color="primary" v-if="group.sub_categories" type="flat" size="small" @click="clickGroup" :class = "[{'rotate90' : openItems}, 'feather-grp-header-arrow']" icon-pack="feather" icon="icon-chevron-right"></vs-button>

        <!-- Group Tooltip -->
        <span class="vs-sidebar--tooltip">{{  group.name }}</span>
      </div>
      <!-- /Group Label -->

      <!-- Group Items -->
      <ul ref="items" :style="styleItems" class="vs-sidebar-group-items">
        <li v-for="(groupItem, index) in group.sub_categories" v-if="group.sub_categories.length" :key="groupItem.id">

          <!-- If item is group -->
          <v-nav-menu-group
            v-if        = "groupItem.sub_categories.length"
            :group      = "groupItem"
            :groupIndex = "Number(`${groupIndex}.${index+1}`)"
            :open       = "isGroupActive(groupItem)"
            :openHover  = "openHover" />

          <!-- Else: Item -->
          <v-nav-mega-menu-item v-else :category="groupItem"></v-nav-mega-menu-item>
        </li>
      </ul>
      <!-- /Group Items -->
  </div>
</template>


<script>
import VNavMegaMenuItem from './VerticalNavMegaMenuItem.vue'

export default {
  name  : 'v-nav-menu-group',
  props : {
    openHover  : { type: Boolean, default: false },
    open       : { type: Boolean, default: false },
    group      : { type: Object },
    groupIndex : { type: Number },
  },
  components: {
    VNavMegaMenuItem,
  },
  data: () => ({
    maxHeight : '0px',
    openItems : false
  }),
  computed: {
    verticalNavMenuItemsMin() { return this.$store.state.verticalNavMenuItemsMin },
    styleItems() {
      return { maxHeight: this.maxHeight }
    },
    itemIcon() {
      return (index) => {
        if (!((index.match(/\./g) || []).length > 1)) return "CircleIcon"
      }
    },
    isGroupActive() {
      return (item) => {
        const path        = this.$route.fullPath
        let open          = false
        const routeParent = this.$route.meta ? this.$route.meta.parent : undefined

        let func = (item) => {
          if (item.sub_categories) {
            item.sub_categories.forEach((item) => {
              if ((path == 'category/'+item.slug || routeParent == item.slug)) { open = true}
              else if (item.sub_categories) { func(item) }
            })
          }
        }

        func(item)
        return open
      }
    },
  },
  watch: {
    // OPEN & CLOSES DROPDOWN ON ROUTE CHANGE
    '$route'() {
      if (this.verticalNavMenuItemsMin) return

      let scrollHeight = this.scrollHeight

      // Collapse Group
      if (this.openItems && !this.open) {

        this.maxHeight = `${scrollHeight}px`
        setTimeout(()  => {
          this.maxHeight = `${0}px`
        }, 50)

      // Expand Group
      } else if (this.open) {

        this.maxHeight = `${scrollHeight}px`
        setTimeout(()  => {
          this.maxHeight = 'none'
        }, 300)
      }
    },
    maxHeight() {
      this.openItems = this.maxHeight != '0px'
    },
    // OPEN AND CLOSES DROPDOWN MENU ON NavMenu COLLAPSE AND DEFAULT VIEW
    '$store.state.verticalNavMenuItemsMin'(val) {
      let scrollHeight = this.$refs.items.scrollHeight

      if (!val && this.open) {

        this.maxHeight = `${scrollHeight}px`
        setTimeout(()  => {
          this.maxHeight = 'none'
        }, 300)
      } else {

        this.maxHeight = `${scrollHeight}px`
        setTimeout(()  => {
          this.maxHeight = '0px'
        }, 50)
      }
      if (val && this.open) {

        this.maxHeight = `${scrollHeight}px`
        setTimeout(()  => {
          this.maxHeight = '0px'
        }, 250)
      }
    }
  },
  methods: {
    clickGroup() {
      if (!this.openHover) {

        let thisScrollHeight = this.$refs.items.scrollHeight

        if (this.maxHeight == '0px') {
          this.maxHeight = `${thisScrollHeight}px`
          setTimeout(() => {
            this.maxHeight = 'none'
          }, 300)

        } else {
          this.maxHeight = `${thisScrollHeight}px`
          setTimeout(() => {
            this.maxHeight = `${0}px`
          }, 50)
        }

        this.$parent.$children.map((child) => {
          if (child.isGroupActive) {
            if (child !== this && (!child.open) && child.maxHeight != '0px') {
              setTimeout(() => {
                child.maxHeight = `${0}px`
              }, 50)
            }
          }
        })
      }
    },
    mouseover() {
      if (this.openHover) {
        let scrollHeight = this.$refs.items.scrollHeight
        this.maxHeight   = `${scrollHeight}px`
      }
    },
    mouseout() {
      if (this.openHover) {
        let scrollHeight = 0
        this.maxHeight   = `${scrollHeight}px`
      }
    }
  },
  mounted() {
    this.openItems = this.open
    if (this.open) { this.maxHeight = 'none' }
  },
}

</script>


<style lang="scss">
@import "@/assets/scss/vuexy/components/verticalNavMenuGroup.scss"
</style>
