<!-- =========================================================================================
  File Name: TheCustomizer.vue
  Description: Template Customizer
  Component Name: TheCustomizer
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="theme-customizer">

    <!-- Open Customizer Button -->
    <vs-button
      :class="{ 'shake-vertical': shake }"
      @click.stop="active=!active"
      size="large"
      color="primary"
      type="filled"
      class="customizer-btn p-4">
      <feather-icon svgClasses="text-white" icon="ShoppingCartIcon" badge_bg="bg-success"  class="cursor-pointer" :badge="cartItems.length" />
      <span class="text-sm block">{{cart_total.grand_total | currency}}</span>
    </vs-button>

    <!-- Customizer Content -->
    <vs-sidebar
      click-not-close
      hidden-background
      position-right
      v-model="active"
      class="items-no-padding w-full">
      <div class="h-full">

        <div class="customizer-header mt-6 flex items-center justify-between px-6">
          <div>
            <h4>CART</h4>
            <small>{{cart_total.total_qty}} item in cart</small>
          </div>
          <feather-icon icon="XIcon" @click.stop="active = false" class="cursor-pointer"></feather-icon>
        </div>

        <vs-divider class="mb-0" />

        <VuePerfectScrollbar class="scroll-area--cart-items-dropdowm pt-4 pb-6" :settings="settings" :key="$vs.rtl">

          <div class="px-3 cart-dropdown">
            <div v-if="cart_total.total_qty == 0" class="text-center">
              <img :src="require('@/assets/images/pages/empty-cart-png.png')" class="h-64" alt="Empty Cart">
            </div>
            <ul v-else class="bordered-items">
              <li
                v-for="(item, index) in cartItems"
                :key="item.id"
                class="vx-row no-gutter cart-item">

                <!-- IMG COL -->
                <div class="vx-col w-1/5 item-img-container bg-white flex items-center cursor-pointer justify-center" @click="$router.push({name: 'product_view', params: {slug: item.slug }}).catch(() => {})">
                  <img :src="base_image(item).path" alt="item" class="cart-dropdown-item-img">
                </div>

                <!-- INFO COL -->
                <div class="vx-col w-4/5 pr-4 pl-2 py-4 flex flex-col justify-center">
                  <div class="flex justify-between">
                    <span @click="$router.push({name: 'product_view', params: {slug: item.slug }}).catch(() => {})" class="font-medium block cursor-pointer cart-item-title truncate">{{ item.name }}</span> <feather-icon icon="XIcon" svgClasses="h-4 w-4 cursor-pointer text-danger float-right" class="hover:text-danger" @click.stop="removeItemFromCart(item)" />
                  </div>
                  <small class="truncate mb-2">{{ item.short_description }}</small>
                  <div class="flex justify-between">
                    <div class="flex items-center">
                      <vs-input-number size="mini" min="1" :max="item.qty" v-model="item.quantity" @input="updateItemQuantity($event, index)"/><span class="text-sm font-medium"><small>x</small> {{ item.cal_price | currency }}</span>
                    </div>
                    <span>{{item.quantity*item.cal_price |currency}}</span>
                  </div>

                </div>
              </li>
            </ul>
          </div>
        </VuePerfectScrollbar>
        <div>

        </div>
        <div class=" absolute bottom-0 w-full">
          <div class="p-2">
            <vs-divider />
            <p class="font-semibold mb-3">Price Details</p>
            <div class="flex justify-between mb-2">
              <span class="text-dark">Sub Total</span>
              <span>{{cart_total.sub_total | currency}}</span>
            </div>
            <div class="flex justify-between mb-2">
              <span class="text-dark">Delivery Charges</span>
              <span v-if="cart_total.shipping"> {{cart_total.shipping|currency}}</span>
              <span v-else class="text-success">Free</span>
            </div>
            <vs-divider />
            <div v-if="cart_total.discount" class="flex justify-between mb-2">
              <span class="text-dark">Discount</span>
              <span class="text-success">{{cart_total.discount |currency}}</span>
            </div>
            <vs-divider />

            <div class="flex justify-between font-semibold mb-3">
              <span>Total</span>
              <span>{{cart_total.grand_total|currency}}</span>
            </div>
          </div>
          <div @click="go_checkout" style="line-height: 30px" class=" rounded-b-lg text-white cursor-pointer font-semibold bg-success p-2 text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light">
            <span class="flex items-center justify-center">
                <feather-icon icon="ShoppingCartIcon" svgClasses="h-4 w-4" />
                <span class="ml-2">Checkout</span>
              </span>
          </div>

        </div>
      </div>
    </vs-sidebar>
  </div>
</template>


<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'

export default {
  data() {
    return {
      shake:false,
      active             : false,
      settings: {
        maxScrollbarLength : 60,
        wheelSpeed         : .60,
      },
    }
  },
  computed: {
    cartItems() {
      return this.$store.state.eCommerce.cartItems.slice().reverse();
    },
    base_image(){
      return (item) =>  item.files.find(function (f) {
        return f.pivot.zone ==='base_image'
      })
    },
    cart_total(){
      let sub_total=0,
        total_qty = 0,
        total=0,
        grand_total=0,
        discount=0,
        shipping=0,
        setting = this.$store.state.settings;
        this.cartItems.forEach(function (item) {
          sub_total+=item.cal_price*item.quantity;
          total_qty+=item.quantity;
        });
        if (Object.keys(this.$store.state.eCommerce.coupon).length>0){
          discount = parseFloat(this.$store.state.eCommerce.coupon.discount.amount);
        }
        total = sub_total-discount;

      if (setting.free_shipping_enabled && parseFloat(setting.free_shipping_min_amount)<parseFloat(total)){
        shipping = 0;
      }else if (this.$store.state.user.shipping){
        shipping = this.$store.state.user.shipping.cost;
      }
      if (total_qty>0)
       grand_total= total+parseFloat(shipping);


      return {
        total,
        total_qty,
        sub_total,
        grand_total,
        discount,
        shipping,
      }
    }
  },
  methods: {
    go_checkout(){
      if (this.$store.state.windowWidth<780 )
        this.active = false;
      this.$router.push('/checkout')
    },
    updateItemQuantity(event, index) {
      const itemIndex = Math.abs(index + 1 - this.cartItems.length)
      this.$store.dispatch("eCommerce/updateItemQuantity", { quantity: event, index: itemIndex })
      if (Object.keys(this.$store.state.eCommerce.coupon).length>0){
        this.m_updateCoupon(this.$store.state.eCommerce.coupon.code);
      }
    },
    removeItemFromCart(item) {
      this.$store.dispatch('eCommerce/toggleItemInCart', item)
      if (Object.keys(this.$store.state.eCommerce.coupon).length>0){
        this.m_updateCoupon(this.$store.state.eCommerce.coupon.code);
      }
    },

  },
  components: {
    VuePerfectScrollbar,
    'v-select': vSelect,
  },
  mounted(){
    let app = this;
    this.$root.$on('shakeCart', function () {
      app.shake = true;
      setTimeout(() => {
        app.shake = false;
      }, 3000)
    });
    this.$root.$on('showCart', function () {
      app.active = true;
    });
    this.$root.$on('hideCart', function () {
      app.active = false;
    });
    if (Object.keys(this.$store.state.eCommerce.coupon).length>0){
      this.m_updateCoupon(this.$store.state.eCommerce.coupon.code);
    }
  }
}

</script>


<style lang="scss">
#theme-customizer {
  .vs-sidebar {
    position: fixed;
    z-index: 52000;
    width: 400px;
    max-width: 100vw;
    // @apply shadow-lg;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08);
  }
}
@media only screen and (max-width: 780px){
  .vs-sidebar {
    width: 100% !important;
  }
}

.customizer-btn {
  position: fixed;
  top: 60%;
  right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 50000;
}

.scroll-area--customizer {
  height: calc(100% - 5rem);
}
  .cart-opener {
    width: 60px;
    height: 60px;
  }
</style>

