<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="relative">

    <div class="vx-navbar-wrapper" :class="classObj">

      <vs-navbar  v-if="$store.state.windowWidth>768 || scroll> -65" class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">

        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon class="inline-flex  cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar" />
        <div :class="{'text-center w-full':$store.state.windowWidth<768}">
          <img :src="require('@/assets/images/logo/Round_logo.png')" @click="$router.push('/')" alt="logo" class="mr-4 ml-4 cursor-pointer" width="100px">
        </div>
          <vs-spacer />

          <search-bar class="hidden md:flex" />


        <vs-spacer />
        <profile-drop-down class="ml-2"></profile-drop-down>
      </vs-navbar>
      <div class="pb-1 pl-1 pr-1 secondary_search" :class="{'pt-1':scroll<-65}" :style="{background:navbarColorLocal, top:scroll>-65?'65px':'0px'}">
        <search-bar v-if="$store.state.windowWidth<768" :onMobile="$store.state.windowWidth<768" />
      </div>

      </vs-navbar>

    </div>
  </div>
</template>


<script>
import Bookmarks            from "./components/Bookmarks.vue"
import SearchBar            from "./components/SearchBar.vue"
import NotificationDropDown from "./components/NotificationDropDown.vue"
import ProfileDropDown      from "./components/ProfileDropDown.vue"

export default {
  name: "the-navbar-vertical",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  data(){
    return {
      scroll:0,
    }
  },
  components: {
    Bookmarks,
    SearchBar,
    NotificationDropDown,
    ProfileDropDown,
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === "dark" && this.navbarColor === "#fff" ? "#10163a" : this.navbarColor
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth
    },
    textColor() {
      return {'text-white': (this.navbarColor != '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor != '#fff' && this.$store.state.theme !== 'dark')}
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },

    // NAVBAR STYLE
    classObj() {
      return "navbar-full"
    },
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    }
  },
  mounted(){
    var app = this;
    window.addEventListener('scroll', function(){
      app.scroll = (document.body.getBoundingClientRect()).top;
    });
  }
}
</script>
<style>
  .navbar-full {
    width: 100% !important;
  }
  .navbar-floating .vx-navbar-wrapper {
    padding: 0;
  }
  .navbar-floating .vx-navbar {
    border-radius: unset;
  }
  .secondary_search {
    position: absolute;
    left: 0px;
    width: 100%;
    background: #fff;
    box-shadow: 1px 20px 20px -20px #00000094;
    transition: 0.5s;
  }
</style>

